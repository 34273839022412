import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate lint`}</strong>{` -- lint certificate details`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate lint <crt-file> [--roots=<root-bundle>]
[--servername=<servername>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate lint`}</strong>{` checks a certificate for common errors and outputs the result in JSON format. It is intended for evaluating Web PKI certificates, and may not be appropriate for internal PKIs.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
Path to a certificate or certificate signing request (CSR) to lint.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--roots`}</strong>{`=`}<inlineCode parentName="p">{`roots`}</inlineCode>{`
Root certificate(s) that will be used to verify the
authenticity of the remote server.`}</p>
    <p><inlineCode parentName="p">{`roots`}</inlineCode>{` is a case-sensitive string and may be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`file`}</strong>{`: Relative or full path to a file. All certificates in the file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`list of files`}</strong>{`: Comma-separated list of relative or full file paths. Every PEM encoded certificate from each file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`directory`}</strong>{`: Relative or full path to a directory. Every PEM encoded certificate from each file in the directory will be used for path validation.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--insecure`}</strong>{`
Use an insecure client to retrieve a remote peer certificate. Useful for
debugging invalid certificates remotely.`}</p>
    <p><strong parentName="p">{`--servername`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
TLS Server Name Indication that should be sent to request a specific certificate from the server.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate lint ./certificate.crt
`}</code></pre>
    <p>{`Lint a remote certificate (using the default root certificate bundle to verify the server):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate lint https://smallstep.com
`}</code></pre>
    <p>{`Lint a remote certificate using a custom root certificate to verify the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate lint https://smallstep.com --roots ./certificate.crt
`}</code></pre>
    <p>{`Lint a remote certificate using a custom list of root certificates to verify the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate lint https://smallstep.com \\
--roots "./certificate.crt,./certificate2.crt,/certificate3.crt"
`}</code></pre>
    <p>{`Lint a remote certificate using a custom directory of root certificates to verify the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate lint https://smallstep.com --roots "./path/to/certificates/"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      